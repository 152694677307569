<template>
  <div>
    <q-form ref="editForm">
      <div class="row">
        <div class="col-md-12 col-lg-12">
          <c-card title="설비 기본정보" class="cardClassDetailForm">
            <template slot="card-button">
              <q-btn-group outline >
                <c-btn label="QR코드 출력" v-if="editable" icon="save_alt" @btnClicked="btnQrCode" />
                <c-btn
                  v-if="editable"
                  :url="saveUrl"
                  :isSubmit="isSave"
                  :param="equipData"
                  :mappingType="mappingType"
                  label="저장"
                  icon="save"
                  @beforeAction="saveInfo"
                  @btnCallback="saveCallback" />
              </q-btn-group>
            </template>
            <template slot="card-detail">
              <div class="col-3">
                <c-text
                  :required="true"
                  :editable="editable"
                  label="설비관리번호"
                  name="equipmentNo"
                  v-model="equipData.equipmentNo">
                </c-text>
              </div>
              <div class="col-3">
                <c-text
                  :required="true"
                  :editable="editable"
                  label="설비명"
                  name="equipmentName"
                  v-model="equipData.equipmentName">
                </c-text>
              </div>
              <div class="col-3">
                <c-equip-class
                  :required="true"
                  :editable="editable"
                  label="설비유형"
                  name="equipmentTypeCd"
                  v-model="equipData.equipmentTypeCd">
                </c-equip-class>
              </div>
              <div class="col-3">
                <c-plant
                  :required="true"
                  :editable="editable && !updateMode"
                  type="edit"
                  name="plantCd"
                  v-model="equipData.plantCd" />
              </div>
              <div class="col-5">
                <c-dept-multi :required="true" label="관리부서" :parentCheckDepts="equipData.managementDepts" :plantCd="equipData.plantCd" name="managementDepts" v-model="equipData.managementDepts" />
              </div>
              <div class="col-5">
                <c-process
                  :editable="editable"
                  label="단위공정"
                  multiple="multiple"
                  :required="true"
                  name="subProcessCd"
                  v-model="equipData.subProcessCd">
                </c-process>
              </div>
              <div class="col-2">
                <c-text
                  :required="true"
                  :editable="editable"
                  label="수량"
                  name="amount"
                  type="number"
                  v-model="equipData.amount">
                </c-text>
              </div>
            </template>
          </c-card>
        </div>
        <div class="col-md-6 col-lg-6">
          <c-card title="설치관련 정보" class="cardClassDetailForm" topClass="topcolor-blue">
            <template slot="card-detail">
              <div class="col-6">
                <c-text
                  :editable="editable"
                  label="설비위치"
                  name="equipmentLocation"
                  v-model="equipData.equipmentLocation">
                </c-text>
              </div>
              <div class="col-6">
                <c-datepicker
                    label="설치일"
                    name="installDate"
                    v-model="equipData.installDate"
                  />
              </div>
              <div class="col-6">
                <c-text
                  :editable="editable"
                  label="설치업체"
                  name="installVendor"
                  v-model="equipData.installVendor">
                </c-text>
              </div>
              <div class="col-6">
                <c-datepicker
                    label="가동일"
                    name="opertationDate"
                    v-model="equipData.opertationDate"
                  />
              </div>
            </template>
          </c-card>
        </div>
        <div class="col-md-6 col-lg-6">
          <c-card title="설치상태 정보" class="cardClassDetailForm" topClass="topcolor-blue">
            <template slot="card-detail">
              <div class="col-6">
                <c-text
                  :editable="editable"
                  label="검사합격번호"
                  name="checkPassNo"
                  v-model="equipData.checkPassNo">
                </c-text>
              </div>
              <div class="col-6">
                <c-datepicker
                    label="합격일"
                    name="checkPassDate"
                    v-model="equipData.checkPassDate"
                  />
              </div>
              <div class="col-6">
                <c-checkbox
                  :editable="editable"
                  :isFlag="true"
                  label="폐기여부"
                  name="discardFlag"
                  v-model="equipData.discardFlag"
                />
              </div>
              <div class="col-6">
                <c-datepicker
                  :editable="editable && equipData.discardFlag=='Y'"
                  label="폐기일"
                  name="discardDate"
                  v-model="equipData.discardDate"
                  />
              </div>
            </template>
          </c-card>
        </div>
        <div class="col-md-6 col-lg-6">
          <c-card title="점검관련 정보" class="cardClassDetailForm" topClass="topcolor-blue">
            <template slot="card-detail">
              <div class="col-3">
                <c-checkbox
                  :editable="editable"
                  :isFlag="true"
                  label="법정설비여부"
                  name="lawEquipmentFlag"
                  v-model="equipData.lawEquipmentFlag"
                  @datachange="datachange"
                />
              </div>
              <div class="col-3">
                <c-select
                  :required="equipData.lawEquipmentFlag == 'Y'"
                  :editable="editable"
                  codeGroupCd="MDM_EQUIP_CYCLE_CD"
                  type="edit"
                  itemText="codeName"
                  itemValue="code"
                  name="inspectionCycleCd"
                  label="법정점검주기"
                  v-model="equipData.inspectionCycleCd"
                ></c-select>
              </div>
              <div class="col-3">
                <c-select
                  :editable="false"
                  codeGroupCd="MDM_EQUIP_GRADE_CD"
                  type="edit"
                  itemText="codeName"
                  itemValue="code"
                  name="equipGradeCd"
                  label="설비등급"
                  v-model="equipData.equipGradeCd"
                ></c-select>
              </div>
              <div class="col-3">
                <c-select
                  codeGroupCd="MDM_EQUIP_CYCLE_CD"
                  type="edit"
                  itemText="codeName"
                  itemValue="code"
                  name="selfCycleCd"
                  label="자체점검주기"
                  v-model="equipData.selfCycleCd"
                ></c-select>
              </div>
              <div class="col-3">
                <c-select
                  codeGroupCd="MDM_EQUIP_CYCLE_CD"
                  type="edit"
                  itemText="codeName"
                  itemValue="code"
                  name="pmCycleCd"
                  label="예방정비주기"
                  v-model="equipData.pmCycleCd"
                ></c-select>
              </div>
              <div class="col-3">
                <c-checkbox
                  :editable="editable"
                  :isFlag="true"
                  label="운전정비여부"
                  name="operFlag"
                  v-model="equipData.operFlag"
                />
              </div>
              <div class="col-3">
                <c-checkbox
                  :editable="editable"
                  :isFlag="true"
                  label="예방정비여부"
                  name="pmFlag"
                  v-model="equipData.pmFlag"
                />
              </div>
              <div class="col-3">
                <c-checkbox
                  :editable="editable"
                  :isFlag="true"
                  label="예측정비여부"
                  name="predictiveFlag"
                  v-model="equipData.predictiveFlag"
                />
              </div>
              <div class="col-3">
                <c-checkbox
                  :editable="editable"
                  :isFlag="true"
                  label="고장정비여부"
                  name="cmFlag"
                  v-model="equipData.cmFlag"
                />
              </div>
            </template>
          </c-card>
        </div>
        <div class="col-md-6 col-lg-6">
          <c-card title="법규관련 정보" class="cardClassDetailForm" topClass="topcolor-blue">
            <template slot="card-detail">
              <div class="col-12">
                <c-multi-select
                  :editable="editable"
                  :isArray="false"
                  codeGroupCd="RELATED_LAWS_CD"
                  itemText="codeName"
                  itemValue="code"
                  label="관련 법규"
                  name="relatedLaws"
                  maxValues="3"
                  v-model="equipData.relatedLaws">
                </c-multi-select>
              </div>
              <div class="col-4">
                <c-checkbox
                  :editable="editable"
                  :isFlag="true"
                  label="유해위험설비 여부"
                  name="psmFlag"
                  v-model="equipData.psmFlag"
                />
              </div>
              <div class="col-4" v-if="equipData.psmFlag == 'Y'">
                <c-select
                  :required="equipData.psmFlag == 'Y'"
                  codeGroupCd="MDM_PSM_TYPE_CD"
                  type="edit"
                  itemText="codeName"
                  itemValue="code"
                  name="psmTypeCd"
                  label="유해위험설비 구분"
                  v-model="equipData.psmTypeCd"
                ></c-select>
              </div>
              <div class="col-4">
                <c-checkbox
                  :editable="editable"
                  :isFlag="true"
                  label="유해위험기계기구 여부"
                  name="hazardousFlag"
                  v-model="equipData.hazardousFlag"
                />
              </div>
            </template>
          </c-card>
        </div>
        <c-dialog :param="popupOptions"></c-dialog>
      </div>
    </q-form>
  </div>
</template>
<script>
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'equipment-info',
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        equipmentCd: '',
        plantCd: '',
        psmFlag: '',
      }),
    },
    equipData: {
      type: Object,
      default: () => ({
        plantCd: null,  // 사업장코드
        equipmentTypeCd: null,  // 설비유형 코드
        equipmentTypeName: '',  // 설비유형명
        equipmentCd: '',  // 설비코드
        equipmentNo: '',  // 설비코드
        equipmentName: '',  // 설비명
        mainProcessCd: '',  // 주공정
        subProcessCd: '',  // 부공정
        managementDepts: '',  // 관리부서
        equipmentLocation: '',  // 설비위치
        installDate: '',  // 설치일자
        installVendor: '',  // 설치업체
        opertationDate: '',  // 가동일자
        checkPassNo: '',  // 검사합격번호
        checkPassDate: '',  // 합격일자
        discardFlag: 'N',
        discardDate: '',  // 폐기일자
        psmFlag: 'N',  // PSM 대상 여부
        psmTypeCd: null,
        amount: '',  // 수량
        lawEquipmentFlag: 'N',  // 법정설비 여부
        inspectionCycleCd: null,  // 법정점검 주기
        selfCycleCd: null,  // 자체점검 주기
        pmCycleCd: null,  // 자체점검 주기
        pmFlag: 'N',  // 예방정비 여부
        cmFlag: 'N',  // 고장정비 여부
        predictiveFlag: 'N',  // 예지정비 여부
        operFlag: 'N', // 운전정비 여부
        relatedLaws: '',  // 관련법규
        recentInspectionDate: '',  // 최근 점검일자
        outsourcingVendor: '',  // 유지보수업체
        hazardousFlag: 'N'
      }),
    },
    contentHeight: null,
  },
  data() {
    return {
      popupOptions: {
        isFull: true,
        target: null,
        title: '',
        visible: false,
        top: '',
        param: {},
        closeCallback: null,
      },
      saveUrl: '',
      mappingType: 'POST',
      insertUrl: '',
      updateUrl: '',
      checkUrl: '',
      editable: true,
      isSave: false,
      updateMode: false,
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.saveUrl = transactionConfig.mdm.equipment.info.insert.url;
      this.checkUrl = selectConfig.mdm.equipment.check.url;
    },
    setSaveValue() {
      return new Promise(resolve => {
        this.equipData.regUserId = this.$store.getters.user.userId;
        this.equipData.chgUserId = this.$store.getters.user.userId;
        this.equipData.psmTypeCd = this.equipData.psmFlag == 'N' ? null : this.equipData.psmTypeCd;

        resolve(true);
      });
    },
    saveValue() {
      return new Promise(resolve => {
        this.isSave = !this.isSave
        resolve(true);
      });
    },
    saveInfo() {
      if (this.popupParam.equipmentCd) {
        this.saveUrl = transactionConfig.mdm.equipment.info.update.url;
        this.mappingType = 'PUT';
      } else {
        this.saveUrl = transactionConfig.mdm.equipment.info.insert.url;
        this.mappingType = 'POST';
      }
      this.$refs['editForm'].validate().then(_result => {
        if (_result) {
          this.$http.url = this.$format(this.checkUrl, this.equipData.equipmentNo);
          this.$http.type = 'GET';
          this.$http.request((_result) => {
            if (this.equipData.equipmentCd || _result.data === 0) {
              window.getApp.$emit('CONFIRM', {
                title: '확인',
                message: '저장하시겠습니까?',
                type: 'info', // success / info / warning / error
                // 확인 callback 함수
                confirmCallback: () => {
                  let promises = [
                    {func: this.setSaveValue,},
                    {func: this.saveValue},
                  ];
                  this.$comm.orderedPromise(promises);
                },
                // 취소 callback 함수
                cancelCallback: () => {
                },
              });
            } else {
              window.getApp.$emit('ALERT', {
                title: '안내',
                message: '동일한 시퀀스 아이디가 존재합니다.', 
                type: 'warning', // success / info / warning / error
              });
              return;
            }
          },);
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    saveCallback(result) {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      if (this.mappingType == 'POST') {
        this.$set(this.popupParam, 'equipmentCd', result.data.equipmentCd)
        this.$set(this.popupParam, 'psmFlag', this.equipData.psmFlag)
        this.$emit('getDetail');
      } else {
        this.$set(this.popupParam, 'psmFlag', this.equipData.psmFlag)
        this.$emit('getDetail');
      }
    },
    datachange() {
      if (this.equipData.lawEquipmentFlag === 'N') {
        this.equipData.inspectionCycleCd = null;
      }
    },
    btnQrCode() {
      let seldata = [];
      seldata.push(this.equipData)
      this.popupOptions.title = "설비 QR코드"; 
      this.popupOptions.param = {
        selectData: seldata,
      };
      this.popupOptions.target = () => import(`${"./equipmentQr.vue"}`);
      this.popupOptions.visible = true;
      this.popupOptions.isFull = false;
      this.popupOptions.width = '800px';
      this.popupOptions.closeCallback = this.closePopup;
    },
    closePopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
    },
  }
};
</script>